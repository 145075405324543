import React, { type FC } from 'react';

export const DefaultLogo: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSurface)';

  return (
    <svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6394_25067)">
    <path d="M151.464 9.95635L146.238 19.8676L151.653 29.586L141.599 24.5283L131.507 29.5227L136.961 19.8676L131.908 10.1324L141.599 15.2084L151.464 9.95635Z" fill="url(#paint0_linear_6394_25067)"/>
    <path d="M141.73 19.931L151.487 9.90847L146.259 19.8212L141.73 19.931Z" fill="url(#paint1_linear_6394_25067)"/>
    <path d="M151.653 29.586L141.599 24.5283L141.708 19.9775L151.653 29.586Z" fill="url(#paint2_linear_6394_25067)"/>
    <path d="M131.507 29.5227L141.73 20L136.982 19.8902L131.507 29.5227Z" fill="url(#paint3_linear_6394_25067)"/>
    <path d="M131.908 10.1323L141.73 20L141.621 15.2295L131.908 10.1323Z" fill="url(#paint4_linear_6394_25067)"/>
    <path d="M141.688 4.06365C138.559 4.06365 135.499 4.99595 132.897 6.74267C130.295 8.48938 128.267 10.9721 127.069 13.8768C125.871 16.7815 125.558 19.9777 126.168 23.0614C126.779 26.1451 128.286 28.9776 130.499 31.2009C132.711 33.4242 135.531 34.9383 138.6 35.5519C141.67 36.1654 144.851 35.8508 147.743 34.6478C150.634 33.4448 153.106 31.4075 154.845 28.7935C156.583 26.1794 157.512 23.106 157.512 19.962C157.509 15.7468 155.841 11.7051 152.874 8.72437C149.907 5.74362 145.884 4.06738 141.688 4.06365ZM141.688 33.7806C138.967 33.7812 136.307 32.971 134.044 31.4525C131.781 29.934 130.017 27.7754 128.975 25.2497C127.934 22.7241 127.661 19.9448 128.192 17.2634C128.722 14.5821 130.033 12.1191 131.957 10.1859C133.881 8.25279 136.333 6.93636 139.002 6.40315C141.671 5.86994 144.438 6.14388 146.952 7.19035C149.466 8.23681 151.614 10.0088 153.126 12.2821C154.637 14.5555 155.444 17.2281 155.443 19.962C155.438 23.6253 153.987 27.1371 151.409 29.7275C148.83 32.3179 145.335 33.7754 141.688 33.7806Z" fill="url(#paint5_linear_6394_25067)"/>
    <g>
    <path d="M141.688 4.8761C138.719 4.87638 135.816 5.76134 133.347 7.41907C130.877 9.07681 128.953 11.4329 127.817 14.1893C126.681 16.9457 126.384 19.9787 126.963 22.9048C127.543 25.8308 128.973 28.5185 131.073 30.6279C133.173 32.7373 135.849 34.1737 138.762 34.7555C141.674 35.3373 144.693 35.0383 147.437 33.8963C150.181 32.7543 152.525 30.8207 154.175 28.3399C155.825 25.8591 156.705 22.9426 156.705 19.9592C156.7 15.9599 155.116 12.1258 152.301 9.29819C149.486 6.47053 145.669 4.8802 141.688 4.8761ZM141.688 33.7806C138.967 33.7812 136.307 32.971 134.044 31.4525C131.781 29.934 130.017 27.7754 128.975 25.2497C127.934 22.7241 127.661 19.9448 128.192 17.2634C128.722 14.5821 130.033 12.1191 131.957 10.1859C133.881 8.25279 136.333 6.93637 139.002 6.40315C141.671 5.86994 144.438 6.14389 146.952 7.19035C149.466 8.23681 151.614 10.0088 153.126 12.2821C154.637 14.5555 155.444 17.2281 155.443 19.962C155.438 23.6253 153.987 27.1371 151.409 29.7275C148.83 32.3179 145.335 33.7754 141.688 33.7806Z" fill="url(#paint6_linear_6394_25067)"/>
    </g>
    <path d="M141.468 0L146.14 15.2703L160.178 19.962L146.14 24.655L141.468 40L136.798 24.655L123.2 19.962L136.798 15.2703L141.468 0Z" fill="url(#paint7_linear_6394_25067)"/>
    <path d="M141.58 19.8184L141.513 0L146.184 15.2703L141.58 19.8184Z" fill="url(#paint8_linear_6394_25067)"/>
    <path d="M160.178 19.962L146.14 24.655L141.58 19.8184L160.178 19.962Z" fill="url(#paint9_linear_6394_25067)"/>
    <path d="M141.359 39.855L141.58 19.8184L136.689 24.51L141.359 39.855Z" fill="url(#paint10_linear_6394_25067)"/>
    <path d="M123.245 19.962L141.585 19.8127L136.842 15.2703L123.245 19.962Z" fill="url(#paint11_linear_6394_25067)"/>
    <path d="M8.75981 14.3311H10.1244V21.7923C10.1244 22.5627 9.94282 23.2505 9.57967 23.8557C9.22018 24.4573 8.71212 24.9324 8.0555 25.2809C7.39888 25.6257 6.62855 25.7981 5.7445 25.7981C4.86045 25.7981 4.09011 25.6257 3.43349 25.2809C2.77687 24.9324 2.26699 24.4573 1.90383 23.8557C1.54434 23.2505 1.36459 22.5627 1.36459 21.7923V14.3311H2.72919V21.6823C2.72919 22.2325 2.85024 22.7222 3.09234 23.1514C3.33445 23.577 3.67927 23.9126 4.12679 24.1584C4.57799 24.4005 5.11722 24.5215 5.7445 24.5215C6.37177 24.5215 6.911 24.4005 7.3622 24.1584C7.8134 23.9126 8.15821 23.577 8.39665 23.1514C8.63876 22.7222 8.75981 22.2325 8.75981 21.6823V14.3311Z" fill={fillColor}/>
    <path d="M14.1172 20.5158V25.6H12.8186V17.1483H14.0732V18.4689H14.1832C14.3813 18.0397 14.6821 17.6949 15.0856 17.4345C15.4891 17.1703 16.01 17.0383 16.6483 17.0383C17.2206 17.0383 17.7213 17.1557 18.1505 17.3904C18.5797 17.6215 18.9135 17.9737 19.1519 18.4469C19.3903 18.9164 19.5096 19.5107 19.5096 20.2297V25.6H18.211V20.3177C18.211 19.6537 18.0386 19.1365 17.6938 18.766C17.349 18.3919 16.8757 18.2048 16.2742 18.2048C15.8596 18.2048 15.4891 18.2947 15.1627 18.4744C14.8399 18.6541 14.5849 18.9164 14.3978 19.2612C14.2108 19.6061 14.1172 20.0242 14.1172 20.5158Z" fill={fillColor}/>
    <path d="M22.0277 25.6V17.1483H23.3263V25.6H22.0277ZM22.688 15.7397C22.4349 15.7397 22.2166 15.6535 22.0332 15.4811C21.8535 15.3087 21.7636 15.1014 21.7636 14.8593C21.7636 14.6172 21.8535 14.41 22.0332 14.2376C22.2166 14.0652 22.4349 13.9789 22.688 13.9789C22.9411 13.9789 23.1576 14.0652 23.3373 14.2376C23.5207 14.41 23.6124 14.6172 23.6124 14.8593C23.6124 15.1014 23.5207 15.3087 23.3373 15.4811C23.1576 15.6535 22.9411 15.7397 22.688 15.7397Z" fill={fillColor}/>
    <path d="M32.761 17.1483L29.6356 25.6H28.315L25.1897 17.1483H26.5983L28.9313 23.8833H29.0193L31.3524 17.1483H32.761Z" fill={fillColor}/>
    <path d="M37.8611 25.7761C37.0468 25.7761 36.3443 25.5963 35.7537 25.2368C35.1668 24.8737 34.7138 24.3675 34.3946 23.7182C34.0792 23.0652 33.9214 22.3059 33.9214 21.4402C33.9214 20.5745 34.0792 19.8115 34.3946 19.1512C34.7138 18.4872 35.1576 17.97 35.7262 17.5995C36.2985 17.2254 36.9661 17.0383 37.7291 17.0383C38.1693 17.0383 38.604 17.1116 39.0331 17.2584C39.4623 17.4051 39.853 17.6435 40.2052 17.9737C40.5573 18.3002 40.8379 18.733 41.047 19.2722C41.2561 19.8115 41.3607 20.4754 41.3607 21.2641V21.8144H34.8458V20.6919H40.0401C40.0401 20.215 39.9447 19.7895 39.754 19.4153C39.5669 19.0411 39.2991 18.7459 38.9506 18.5294C38.6058 18.313 38.1986 18.2048 37.7291 18.2048C37.2119 18.2048 36.7643 18.3332 36.3865 18.59C36.0123 18.8431 35.7244 19.1732 35.5226 19.5804C35.3209 19.9876 35.22 20.4241 35.22 20.89V21.6383C35.22 22.2766 35.33 22.8176 35.5501 23.2615C35.7739 23.7017 36.0839 24.0373 36.48 24.2684C36.8762 24.4959 37.3366 24.6096 37.8611 24.6096C38.2023 24.6096 38.5104 24.5619 38.7855 24.4665C39.0643 24.3675 39.3046 24.2207 39.5064 24.0263C39.7081 23.8282 39.864 23.5825 39.9741 23.289L41.2286 23.6411C41.0965 24.0667 40.8746 24.4408 40.5628 24.7636C40.251 25.0828 39.8658 25.3322 39.4073 25.512C38.9488 25.688 38.4334 25.7761 37.8611 25.7761Z" fill={fillColor}/>
    <path d="M43.4813 25.6V17.1483H44.7358V18.4249H44.8239C44.9779 18.0067 45.2567 17.6674 45.6602 17.4069C46.0637 17.1465 46.5186 17.0163 47.0248 17.0163C47.1202 17.0163 47.2394 17.0181 47.3825 17.0218C47.5255 17.0254 47.6338 17.0309 47.7071 17.0383V18.3589C47.6631 18.3478 47.5622 18.3313 47.4045 18.3093C47.2504 18.2837 47.0872 18.2708 46.9148 18.2708C46.5039 18.2708 46.1371 18.357 45.8143 18.5294C45.4952 18.6982 45.242 18.9329 45.055 19.2337C44.8715 19.5309 44.7798 19.8702 44.7798 20.2517V25.6H43.4813Z" fill={fillColor}/>
    <path d="M54.0709 25.6V14.3311H55.4355V19.3493H61.4441V14.3311H62.8087V25.6H61.4441V20.5598H55.4355V25.6H54.0709Z" fill={fillColor}/>
    <path d="M68.9433 25.7761C68.1803 25.7761 67.5109 25.5945 66.9349 25.2313C66.3627 24.8682 65.9152 24.3601 65.5924 23.7072C65.2732 23.0542 65.1137 22.2912 65.1137 21.4182C65.1137 20.5378 65.2732 19.7693 65.5924 19.1127C65.9152 18.4561 66.3627 17.9462 66.9349 17.583C67.5109 17.2199 68.1803 17.0383 68.9433 17.0383C69.7063 17.0383 70.3739 17.2199 70.9462 17.583C71.5221 17.9462 71.9696 18.4561 72.2888 19.1127C72.6116 19.7693 72.773 20.5378 72.773 21.4182C72.773 22.2912 72.6116 23.0542 72.2888 23.7072C71.9696 24.3601 71.5221 24.8682 70.9462 25.2313C70.3739 25.5945 69.7063 25.7761 68.9433 25.7761ZM68.9433 24.6096C69.5229 24.6096 69.9998 24.461 70.3739 24.1639C70.7481 23.8667 71.0251 23.4761 71.2048 22.9919C71.3845 22.5077 71.4744 21.9831 71.4744 21.4182C71.4744 20.8533 71.3845 20.3269 71.2048 19.839C71.0251 19.3511 70.7481 18.9568 70.3739 18.656C69.9998 18.3552 69.5229 18.2048 68.9433 18.2048C68.3637 18.2048 67.8869 18.3552 67.5127 18.656C67.1385 18.9568 66.8616 19.3511 66.6818 19.839C66.5021 20.3269 66.4122 20.8533 66.4122 21.4182C66.4122 21.9831 66.5021 22.5077 66.6818 22.9919C66.8616 23.4761 67.1385 23.8667 67.5127 24.1639C67.8869 24.461 68.3637 24.6096 68.9433 24.6096Z" fill={fillColor}/>
    <path d="M76.199 14.3311V25.6H74.9005V14.3311H76.199Z" fill={fillColor}/>
    <path d="M81.9141 25.7761C81.2098 25.7761 80.588 25.5982 80.0488 25.2423C79.5096 24.8829 79.0877 24.3766 78.7832 23.7237C78.4788 23.0671 78.3266 22.2912 78.3266 21.3962C78.3266 20.5085 78.4788 19.7381 78.7832 19.0852C79.0877 18.4322 79.5114 17.9278 80.0543 17.572C80.5972 17.2162 81.2245 17.0383 81.9361 17.0383C82.4864 17.0383 82.921 17.13 83.2402 17.3134C83.563 17.4931 83.8088 17.6986 83.9775 17.9297C84.1499 18.1571 84.2838 18.3442 84.3792 18.4909H84.4892V14.3311H85.7878V25.6H84.5332V24.3014H84.3792C84.2838 24.4555 84.1481 24.6499 83.972 24.8847C83.7959 25.1158 83.5447 25.323 83.2182 25.5065C82.8917 25.6862 82.457 25.7761 81.9141 25.7761ZM82.0902 24.6096C82.6111 24.6096 83.0513 24.4738 83.4108 24.2024C83.7703 23.9273 84.0435 23.5476 84.2306 23.0634C84.4177 22.5755 84.5112 22.0124 84.5112 21.3742C84.5112 20.7432 84.4195 20.1911 84.2361 19.7179C84.0527 19.2411 83.7813 18.8706 83.4218 18.6065C83.0623 18.3387 82.6184 18.2048 82.0902 18.2048C81.5399 18.2048 81.0814 18.346 80.7146 18.6285C80.3514 18.9073 80.0781 19.2869 79.8947 19.7675C79.715 20.2443 79.6251 20.7799 79.6251 21.3742C79.6251 21.9758 79.7168 22.5223 79.9002 23.0139C80.0873 23.5018 80.3624 23.8906 80.7256 24.1804C81.0924 24.4665 81.5473 24.6096 82.0902 24.6096Z" fill={fillColor}/>
    <path d="M88.4917 25.6V17.1483H89.7902V25.6H88.4917ZM89.152 15.7397C88.8988 15.7397 88.6806 15.6535 88.4972 15.4811C88.3174 15.3087 88.2276 15.1014 88.2276 14.8593C88.2276 14.6172 88.3174 14.41 88.4972 14.2376C88.6806 14.0652 88.8988 13.9789 89.152 13.9789C89.4051 13.9789 89.6215 14.0652 89.8012 14.2376C89.9846 14.41 90.0764 14.6172 90.0764 14.8593C90.0764 15.1014 89.9846 15.3087 89.8012 15.4811C89.6215 15.6535 89.4051 15.7397 89.152 15.7397Z" fill={fillColor}/>
    <path d="M93.6125 20.5158V25.6H92.3139V17.1483H93.5685V18.4689H93.6785C93.8766 18.0397 94.1774 17.6949 94.5809 17.4345C94.9844 17.1703 95.5053 17.0383 96.1436 17.0383C96.7158 17.0383 97.2165 17.1557 97.6457 17.3904C98.0749 17.6215 98.4087 17.9737 98.6472 18.4469C98.8856 18.9164 99.0048 19.5107 99.0048 20.2297V25.6H97.7063V20.3177C97.7063 19.6537 97.5338 19.1365 97.189 18.766C96.8442 18.3919 96.371 18.2048 95.7694 18.2048C95.3549 18.2048 94.9844 18.2947 94.6579 18.4744C94.3351 18.6541 94.0802 18.9164 93.8931 19.2612C93.706 19.6061 93.6125 20.0242 93.6125 20.5158Z" fill={fillColor}/>
    <path d="M104.934 28.9455C104.307 28.9455 103.768 28.8648 103.317 28.7033C102.866 28.5456 102.49 28.3365 102.189 28.0761C101.892 27.8193 101.655 27.5442 101.479 27.2507L102.513 26.5244C102.631 26.6785 102.779 26.8545 102.959 27.0526C103.139 27.2544 103.385 27.4286 103.696 27.5754C104.012 27.7258 104.425 27.801 104.934 27.801C105.617 27.801 106.18 27.6359 106.624 27.3057C107.068 26.9756 107.289 26.4584 107.289 25.7541V24.0373H107.179C107.084 24.1914 106.948 24.3821 106.772 24.6096C106.6 24.8333 106.35 25.0333 106.024 25.2093C105.701 25.3817 105.265 25.4679 104.714 25.4679C104.032 25.4679 103.419 25.3065 102.877 24.9837C102.337 24.6609 101.91 24.1914 101.595 23.5751C101.283 22.9589 101.127 22.2105 101.127 21.3301C101.127 20.4644 101.279 19.7106 101.584 19.0687C101.888 18.423 102.312 17.9242 102.855 17.572C103.397 17.2162 104.025 17.0383 104.736 17.0383C105.287 17.0383 105.723 17.13 106.046 17.3134C106.372 17.4931 106.622 17.6986 106.794 17.9297C106.97 18.1571 107.106 18.3442 107.201 18.4909H107.334V17.1483H108.588V25.8421C108.588 26.5684 108.423 27.159 108.093 27.6139C107.766 28.0724 107.326 28.4081 106.772 28.6208C106.222 28.8372 105.609 28.9455 104.934 28.9455ZM104.89 24.3014C105.411 24.3014 105.852 24.1822 106.211 23.9438C106.571 23.7053 106.844 23.3624 107.031 22.9148C107.218 22.4673 107.312 21.9317 107.312 21.3081C107.312 20.6992 107.22 20.1618 107.036 19.6959C106.853 19.2301 106.582 18.8651 106.222 18.601C105.863 18.3368 105.419 18.2048 104.89 18.2048C104.34 18.2048 103.882 18.3442 103.515 18.623C103.152 18.9018 102.878 19.2759 102.695 19.7455C102.515 20.215 102.425 20.7359 102.425 21.3081C102.425 21.8951 102.517 22.4141 102.7 22.8653C102.888 23.3128 103.163 23.665 103.526 23.9218C103.893 24.1749 104.348 24.3014 104.89 24.3014Z" fill={fillColor}/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_6394_25067" x1="131.507" y1="19.7705" x2="151.653" y2="19.7705" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6394_25067" x1="141.73" y1="14.9197" x2="151.487" y2="14.9197" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6394_25067" x1="141.599" y1="24.7817" x2="151.653" y2="24.7817" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6394_25067" x1="131.507" y1="24.7071" x2="141.73" y2="24.7071" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6394_25067" x1="131.908" y1="15.0662" x2="141.73" y2="15.0662" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6394_25067" x1="125.866" y1="19.9606" x2="157.512" y2="19.9606" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6394_25067" x1="126.675" y1="19.9606" x2="156.705" y2="19.9606" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6394_25067" x1="123.2" y1="20" x2="160.179" y2="20" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6394_25067" x1="141.513" y1="9.90848" x2="146.184" y2="9.90848" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6394_25067" x1="141.58" y1="22.236" x2="160.179" y2="22.236" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6394_25067" x1="136.689" y1="29.8367" x2="141.58" y2="29.8367" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6394_25067" x1="123.245" y1="17.6162" x2="141.585" y2="17.6162" gradientUnits="userSpaceOnUse">
    <stop stopColor="#36D9FF"/>
    <stop offset="1" stopColor="#680EE6"/>
    </linearGradient>
    <clipPath id="clip0_6394_25067">
    <rect width="160" height="40" fill={fillColor}/>
    </clipPath>
    </defs>
    </svg>
  )
}
