import React, { type FC, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type OptionInterface } from '../../../../interfaces';
import { BetweenAcountsForm } from '../../BetweenAcountsSection/BetweenAccountsForm/BetweenAccountsForm';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { ButtonComponent } from '../../../../components';
import { ChangeIcon } from '../../../../components/icons/ChangeIcon';
import { type SetStateAction } from '../../../../components/PasswordInputComponent/PasswordInputComponent';
import { getCurrencySymbol, toFixed } from '../../../../helpers/util';

import style from './SectionMobileTransactionsBetweenAccounts.module.css';

interface InterfaceSectionMobileTransactionsBetweenAccounts {
  setStep: (el: number) => void
  fromAccount: IAccount | null
  setFromAccount: (value: SetStateAction<IAccount | null>) => void
  toAccount: IAccount | null
  setToAccount: (value: SetStateAction<IAccount | null>) => void
  toAccountValue: number
  setToAccountValue: (el: number) => void
  fromAccountValue: number
  setFromAccountValue: (el: number) => void
}

export const SectionMobileTransactionsBetweenAccounts: FC<InterfaceSectionMobileTransactionsBetweenAccounts> = ({
  setStep,
  fromAccount,
  setFromAccount,
  toAccount,
  setToAccount,
  toAccountValue,
  setToAccountValue,
  fromAccountValue,
  setFromAccountValue
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accounts } = useAppSelector((state) => state);
  const { rates } = useAppSelector(state => state.currencyRates);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [amountFromValue, setAmountFromValue] = useState('');
  const [amountToValue, setAmountToValue] = useState('');

  const options: OptionInterface[] = useMemo((): OptionInterface[] => {
    return accounts.map((account) => ({
      label: `${account?.type?.name ?? ''} ${account?.cfdAccountCurrency?.symbol ?? ''}`,
      value: account._id,
      symbol: account?.cfdAccountCurrency?.symbol ?? '',
      amount: `${getCurrencySymbol(account?.cfdAccountCurrency?.symbol ?? '')} ${toFixed((account?.balance ?? 0), 2)}`
    }))
  }, [accounts]);

  const changeBtnHandle = (): void => {
    setFromAccount(toAccount);
    setToAccount(fromAccount);
  }

  const transferFundsBtnHandler = (): void => {
    setStep(2);
  }

  const findToRate = (): number => {
    const rate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    return rate ?? 0
  }

  const handleChangeToAmount = (value: string): void => {
    setFromAccountValue(Number(value));
    const toRate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    if (toRate === undefined) {
      setToAccountValue(0);

      return;
    }

    setToAccountValue(Number(value) * toRate)
  }

  useEffect(() => {
    if (fromAccount === null) return;

    const getRates = async (): Promise<void> => {
      setIsBtnDisabled(true);
      await dispatch(getCurrencyRates(fromAccount.cfdAccountCurrency.symbol));
      setIsBtnDisabled(false);
    }

    getRates();
  }, [fromAccount]);

  useEffect(() => {
    if (!fromAccount?.balance) return;
    setErrorAmount(Number(amountFromValue) > fromAccount?.balance);
  }, [amountFromValue, fromAccount])

  useEffect(() => {
    accounts.forEach((account) => {
      if (account.isActive) {
        setFromAccount(account)
      } else if (toAccount === null) {
        setToAccount(account);
      }
    })
  }, [accounts]);

  useEffect(() => {
    const toRate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    if (toRate === undefined) {
      setToAccountValue(0);

      return;
    }

    setToAccountValue(Number(fromAccountValue) * toRate)
  }, [rates, fromAccountValue, toAccount]);

  return (
    <>
      <div className={ style.transferWrapper }>
        <div className={ style.transferForm }>
          <p className={ style.label }>{ `${t('labels.from')}:` }</p>
          <BetweenAcountsForm
            options={options}
            fromAccount={fromAccount}
            toAccount={toAccount}
            changeToValue={handleChangeToAmount}
            direction='from'
            error={ errorAmount }
            value={ amountFromValue }
            setValue={ setAmountFromValue }
            changeOption={(value) => { setFromAccount(value); }}
          />
        </div>
        <div className={ style.changeBtnSection }>
          <hr className={ style.line }/>
          <button className={ style.changeBtn } onClick={ changeBtnHandle } disabled={isBtnDisabled}>
            <ChangeIcon color={!isBtnDisabled ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-State-layers-on-surface-opacity_12)'}/>
          </button>
          <hr className={ style.line }/>
        </div>
        <div className={ style.transferForm }>
          <p className={ style.label }>{ `${t('labels.to')}:` }</p>
          <BetweenAcountsForm
            options={options}
            fromAccount={fromAccount}
            toAccountValue={toAccountValue}
            toAccount={toAccount}
            direction='to'
            value={ amountToValue }
            setValue={ setAmountToValue }
            changeOption={(value) => { setToAccount(value); }}
          />
        </div>
      </div>
      <div className={ style.footerWrapper }>
        <div className={ style.btnConfirmContainer }>
          <ButtonComponent onClick={ transferFundsBtnHandler } disabled={toAccountValue === 0 || isBtnDisabled}>
          { t('labels.transfer_funds') }
          </ButtonComponent>
        </div>
        <div className={ style.rateContainer }>
          <div className={ style.rate }>
            <span className={ style.rateFrom }>1</span>
            <span className={ style.rateFrom }>{ fromAccount?.cfdAccountCurrency.symbol }</span>
            <span className={ style.rateFrom }>=</span>
            <span className={ style.rateTo }>{ findToRate() }</span>
            <span className={ style.rateTo }>{ toAccount?.cfdAccountCurrency.symbol }</span>
          </div>
          {/* <span className={ style.feeContainer }>Exchange Fee: 0%</span> */}
        </div>
      </div>
    </>
  )
}
