import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthWrapper } from './auth/AuthWrapper';
import { SnackbarWrapper, RunningTickerComponent } from './components';
import { defineDefaultLangCodeCorrect, getLanguages } from './redux/reducers/language';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { selectLanguage } from './redux/selectors/language';
import { getAlertMessage } from './redux/reducers/alertMessage';

import styles from './App.module.css';

function App (): JSX.Element {
  const dispatch = useAppDispatch();
  const activeLanguage = useAppSelector(selectLanguage);
  const { isAlertMessage } = useAppSelector((state) => state.alertMessage);

  useEffect(() => {
    Promise.all([
      dispatch(defineDefaultLangCodeCorrect()),
      dispatch(getLanguages()),
      dispatch(getAlertMessage())
    ]);
  }, []);

  return (
    <BrowserRouter>
      <SnackbarWrapper>
        <div className={ styles.appContainer }>
          { isAlertMessage && <RunningTickerComponent /> }
          <div className={ styles.app }>
            <AuthWrapper key={activeLanguage}/>
          </div>
        </div>
      </SnackbarWrapper>
    </BrowserRouter>
  );
}

export default App;
// test
