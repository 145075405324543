import React, { forwardRef, useState } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import { ModalImageView } from '../../../components';

import style from './ContentLiveChatComponent.module.css';
import { t } from 'i18next';
import i18n from '../../../i18n';

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
};

export const ContentLiveChatComponent = forwardRef<HTMLDivElement, object>((props, ref) => {
  const chat = useAppSelector((state) => state.chat);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const openImageHandler = (url: string): void => {
    const urlServerPath = `${process.env.REACT_APP_IMAGES_URL}/${url}`;
    setImageUrl(urlServerPath);
    setOpenImageModal(true);
  }

  return (
    <>
    <ModalImageView
      open={ openImageModal }
      setOpen={ setOpenImageModal }
      image={ imageUrl }
    />
      <div className={ style.bodyLiveChat } ref={ ref }>
        <div className={ style.messageWrapper }>
          <div className={ style.messageInfoContainer }>
            { (chat.messages.length > 0) && chat.messages.map(({ sender, content, _id, tempMessageId, createdAt, isRead, fileName }) => {
              if (sender !== userId) {
                return (
                  <div key={ _id ?? tempMessageId }>
                    <div className={ style.messageIconContainer } >
                      <div className={ style.infoContainer }>
                        <p className={ style.infoContent }>{ (new Date(createdAt)).toLocaleString(i18n.language, options) }</p>
                      </div>
                    </div>
                    <div className={ style.messageReciveContainer }>
                      <p className={ style.messageReciveText }>
                        { fileName !== undefined && fileName.length > 0
                          ? <>
                              <span className={ style.fileText } onClick={() => { openImageHandler(fileName); } }>{ fileName }</span>
                              <br/>
                            </>
                          : null
                        }
                        { content }
                      </p>
                    </div>
                  </div>
                )
              }
              return (
                <div className={ style.messageContainer } key={ _id ?? tempMessageId }>
                  <div className={ style.messageSendContainer }>
                    <div className={ style.messageSend }>
                      <p className={ style.messageSendText }>
                        { fileName !== undefined && fileName.length > 0
                          ? <>
                              <span className={ style.fileText } onClick={() => { openImageHandler(fileName); } }>{ fileName }</span>
                              <br/>
                            </>
                          : null
                        }
                        { content }
                      </p>
                    </div>
                    <p className={ style.messageSendStatus }>{ isRead ? t('liveChat.massage_statuses.read') : t('liveChat.massage_statuses.unread') }</p>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  </>
  )
})

ContentLiveChatComponent.displayName = 'ContentLiveChatComponent';
