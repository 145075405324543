import React, { type FC, useState, type ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent, CheckboxComponent, useSnackbar } from '../../../components';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { createSavingInvestment } from '../../../redux/reducers/savingInvestment';
import { type SavingOption } from '../../../interfaces/SavingInterface';
import { getTotalBalance } from '../../../redux/reducers/cfd';
import { getAccounts } from '../../../redux/reducers/accounts';
import { incrementSavingAccountBalance } from '../../../redux/reducers/savingAccounts';
import { type PayloadInterface } from '../../../interfaces';
import { handleError } from '../../../helpers';
import { type TSavingSection } from '../../../pages/Savings/interface/savingsPageInterfaces';

import style from './FooterModalAssetsSubscribe.module.css';

interface InterfaceFooterModalAssetsSubscribe {
  step: number
  setStep: (el: number) => void
  formData: {
    selectedAsset?: string
    currencyId: string
    releaseTime?: Date
    investedAmount?: number | string
    userId?: string
    selectedOption?: SavingOption
    cfdAccountId?: string
  }
  setOpen: (el: boolean) => void
  setSection?: (el: TSavingSection) => void
}

export const FooterModalAssetsSubscribe: FC<InterfaceFooterModalAssetsSubscribe> = ({ step, setStep, formData, setOpen, setSection = () => {} }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const { _id: userId } = useAppSelector(state => state.user);

  const savingsPageStorage = 'Savings-Page/segment-button';
  const accountTableHeaderStorage = 'Savings_Page/AccountTable';

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { target: { checked } } = event;
    setConfirmAgreement(checked);
  }

  const handleClick = async (): Promise<void> => {
    if (userId !== undefined && formData.investedAmount !== undefined) {
      setIsButtonLoading(true)
      const response = await dispatch(createSavingInvestment(formData));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsButtonLoading(false);

        return;
      }
      await dispatch(getTotalBalance(userId));
      await dispatch(getAccounts(userId));
      dispatch(incrementSavingAccountBalance({ currencyId: formData.currencyId, amount: String(formData.investedAmount) }));
      setIsButtonLoading(false)
      setStep(2);
    }
  }

  const goToAccountsBtnHandle = (): void => {
    const accountStorage = localStorage.getItem(accountTableHeaderStorage);
    const parseAccountStorage = accountStorage ? JSON.parse(accountStorage) : {};
    const isFlexible = parseAccountStorage?.isFlexible ? parseAccountStorage?.isFlexible : 'flexible'
    localStorage.setItem(accountTableHeaderStorage, JSON.stringify({ ...parseAccountStorage, currencyId: formData.currencyId, isFlexible }));
    localStorage.setItem(savingsPageStorage, 'Accounts');
    setOpen(false);
    setSection('Accounts')
  }

  useEffect(() => {
    const invalidAmounts = /^$|^0*\.?0*$/;
    if (confirmAgreement) {
      setDisableBtn(invalidAmounts.test(String(formData?.investedAmount)));
    }
  }, [formData, confirmAgreement])

  return (
    <footer className={ style.footerWrapper }>
        { step === 1
          ? <>
          <div className={style.footerAgreement}>
            <CheckboxComponent onChange={handleCheckbox} checked={confirmAgreement} />
            <p className={style.footerAgreementText}>{t('savings_page.subscribe_modal.I_have_read_and_agree')}
              <a href='https://univerholdingcompanylimited.com/privacy-policy-2/' className={style.footerAgreementLink}>
                {t('savings_page.subscribe_modal.this_link_name')}
              </a>
            </p>
          </div>
          <div className={style.footerBtnContainer}>
            <ButtonComponent disabled={disableBtn} loading={isButtonLoading} onClick={() => { handleClick(); }}>{ t('labels.confirm') }</ButtonComponent>
          </div>
        </>
          : <div className={ style.btnsWrapper } >
              <div className={ style.btnContinue }>
                <ButtonComponent variant='outlined' customInlineStyles={{ paddingLeft: '10px', paddingRight: '10px' }} onClick={() => { setOpen(false); setStep(1) }}>
                  <span className={ style.btnTxt }>{t('savings_page.subscribe_modal.Continue_in_Savings')}</span>
                </ButtonComponent>
              </div>
              <div className={ style.btnView }>
                <ButtonComponent onClick={ goToAccountsBtnHandle }>
                  {t('savings_page.subscribe_modal.View_Savings_Accounts')}
                </ButtonComponent>
              </div>
          </div> }
    </footer>
  )
}
