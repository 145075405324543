import React, { useState, type FC, type MouseEvent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { useAppSelector } from '../../../hooks/redux';
import { MobileChatIcon } from '../../icons/MobileChatIcon';
import { menuItemsData, type MenuItemInterface } from '../../NavigationRail/NavigationRail';
import { useWindowSize } from '../../../hooks/useWindowSize';

import style from '../Footer.module.css';

interface InterfaceMobileFooter {
  openLiveChat: boolean
  setOpenLiveChat: (el: boolean) => void
  handleChatBtn: (e: MouseEvent<HTMLButtonElement>) => void
  setOpenMenu: (el: boolean) => void
}

export const MobileFooter: FC<InterfaceMobileFooter> = ({ openLiveChat, setOpenLiveChat, handleChatBtn, setOpenMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [widthScreen] = useWindowSize();
  const { _id: userId } = useAppSelector((state) => state.user);
  const chat = useAppSelector((state) => state.chat);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const isMobile = widthScreen <= 599

  const handleMobileChatBtn = (event: MouseEvent<HTMLButtonElement>, link: string): void => {
    navigate(link);
    setOpenLiveChat(false);
    if (isMobile) setOpenMenu(false);
  }

  useEffect(() => {
    if (chat?.messages?.length > 0) {
      const count = chat.messages.reduce((accumValue, { sender, isRead }) => {
        if (sender !== userId && !isRead) {
          return accumValue++;
        }
        return accumValue;
      }, 0);
      setUnreadMessages(count);
    }
  }, [chat, userId]);

  return (
    <footer className={ style.mobileFooterWrapper }>
    {
      menuItemsData.map(({ link, logo, title }: MenuItemInterface, key) => {
        return (
          <div className={ style.mobileNavCell } key={ key }>
            <button className={ style.mobileBtnContainer } onClick={(e) => { handleMobileChatBtn(e, link); } }>
                <div className={`${style.menuItemImageWrapper} ${((link.startsWith(location.pathname)) && !openLiveChat) ? style.selectedMenuImageItem : null}`} >
                  { logo }
                </div>
                <span className={ style.menuLink } >
                  { title }
                </span>
            </button>
          </div>
        )
      })
    }
    <div className={ style.chatCell }>
      <button className={ style.chatMobileBtn } onClick={ handleChatBtn }>
        <div className={ `${style.iconWrapper} ${openLiveChat ? style.iconWrapperChecked : null}` }>
          <div className={ style.badgeWrapper }>
            <p className={ style.badgeWrapperText }>
              { unreadMessages <= 1000 ? unreadMessages : '1K+' }
            </p>
          </div>
          <MobileChatIcon />
        </div>
        <span className={ style.chatbtnText }>{ t('footer.labels.live_chat') }</span>
      </button>
    </div>
  </footer>
  )
}
