import React, { type FC } from 'react';
import logo from '../../assets/images/logo.png';

interface LogoIconProps {
  className?: string
}

export const LogoIcon: FC<LogoIconProps> = ({ className }) => {
  return (
    <img src={logo} alt="logo" className={className} />
  )
}
