import React, { type FC, useEffect, useState } from 'react';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useAppSelector } from '../../../hooks/redux';
import { MobileHeaderLiveChatComponent } from './MobileHeaderLiveChatComponent/MobileHeaderLiveChatComponent';

import style from './HeaderLiveChatComponent.module.css';
import { t } from 'i18next';

type SetStateAction<T> = T | ((prevState: T) => T);

interface HeaderLiveChatComponentInterface {
  showBody: boolean
  setShowBody: (el: SetStateAction<boolean>) => void
  setOpen: (el: boolean) => void
}

export const HeaderLiveChatComponent: FC<HeaderLiveChatComponentInterface> = ({ showBody, setOpen, setShowBody }) => {
  const chat = useAppSelector((state) => state.chat);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  useEffect(() => {
    if (chat?.messages?.length > 0) {
      const count = chat.messages.reduce((accumValue, { sender, isRead }) => {
        if (sender !== userId && !isRead) {
          return accumValue + 1;
        }
        return accumValue;
      }, 0);
      setUnreadMessages(count);
    }
  }, [chat, userId]);

  return (
    <>
      <div className={ style.headerLiveChat }>
        <div className={ style.headerLiveChatTitleContainer }>
          <p className={ style.headerLiveChatTitle }>{t('liveChat.title')}</p>
          <div className={ style.dotStatisLiveChat }>
            { showBody
              ? <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 4 4" fill="none">
                  <circle cx="2" cy="2" r="2" fill="var(--CFD-theme-System-Error)"/>
                </svg>
              : <div className={ style.unreadMessages }>
                  <p className={ style.unreadMessagesText }>{ unreadMessages < 1000 ? unreadMessages : '1K+' }</p>
                </div>
            }
          </div>
        </div>
        <div className={ style.headerOptions }>
          <button className={ style.headerBtnContainer } onClick={() => { setShowBody((prevState) => !(prevState)) }}>
            <RemoveOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
          <button className={ style.headerBtnContainer } onClick={() => { setOpen(false) }}>
            <CloseOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
        </div>
      </div>
      <MobileHeaderLiveChatComponent />
    </>
  )
}
